<!--
 * @Author: steven 1105624290@qq.com
 * @Date: 2022-07-19 10:04:39
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2022-07-27 14:23:37
 * @FilePath: /ytjj-mobile/src/views/pd/list/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="pd_list">
        <div class="header">
            <div class="plan_id">
                <!-- <van-icon @click="goback" class="icon_top" name="arrow-left" /> -->
                <div></div>
                <div>探究资讯</div>
                <!-- <van-icon @click="showLogoOut" class="icon_top" name="ellipsis" /> -->
                <img @click="showLogoOut" class="icon_top_right" src="@/assets/pd/choose.png" alt="">
            </div>
        </div>
        <div class="body">
            <div class="content" v-if="!loading" :class="{content1:!tableData.length}">
                <div class="title">
                    <div class="left_title">
                        <span class="all_class">总课程</span>
                        <span class="num">{{total}}</span>
                    </div>
                    <div class="right_select">
                        <van-dropdown-menu :active-color="'#4EB8B8'">
                            <van-dropdown-item @change="chooseItem" v-model="queryParams.typeId" :options="option1" />
                        </van-dropdown-menu>
                    </div>
                </div>
                <div class="text_content" v-if="tableData.length">
                    <div class="item" v-for="(item,index) in tableData" :key="index" @click="goDetails(item)">
                        <div class="top_img">
                            <!-- <img class="class-img" :src="item.courseImg + '?x-oss-process=image/resize,h_200,w_300'" alt=""> -->
                            <van-image class="class-img" :src="item.courseImg">
                                <div
                                    class="classification"
                                    :class="{
                                    red: item.typeName === '入门必看',
                                    yellow: item.typeName === 'EAL',
                                    blue: item.typeName === '推荐文章',
                                    color1: item.typeName === '策略与方法',
                                    color2: item.typeName === '理论指导实践',
                                    color3: item.typeName === '家庭探究',
                                    color4: item.typeName === '管理层探究',
                                    color5: item.typeName === '其他',
                                    }"
                                >
                                    {{ item.typeName }}
                                </div>
                            </van-image>
                        </div>
                        <div class="word_text">
                            <div class="title">
                                {{ item.courseName }}
                            </div>
                            <div class="introduction">
                                {{ item.courseIntro }}
                            </div>
                            <div class="time">
                                <img class="clock_icon" src="@/assets/pd/Layer 23@2x.png" alt="">
                                <div class="proposal">建议时间：</div>
                                <div class="time_num">{{ item.courseTime }}分钟</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!tableData.length">
                    <van-empty description="暂无PD培训课程"></van-empty>
                </div>
            </div>
            <van-loading v-if="loading" type="spinner" />
        </div>
    </div>
</template>

<script>
    import { curriculumListApi, pdListApi } from "@/api/pd/index";
    export default {
        data() {
            return {
                queryParams:{
                    typeId:""
                },
                option1:[],
                tableData:[],
                total:"",
                loading:false,
                scroll:0,
            }
        },
        methods:{
            handleScroll () {
                // this.scroll  = document.documentElement &&  document.documentElement.scrollTop
                this.scroll  = document.documentElement && document.getElementsByClassName("content")[0].scrollTop;
            },
            // 跳转详情
            goDetails(item){
                this.$router.push({
                    name: "pdDetails",
                    params: {
                        id: item.id,
                        typeId: item.typeId,
                    },
                });
            },
            goback(){
                this.$router.go(-1)
            },
            showLogoOut(){
                this.$store.commit("setIsShowLogo",true);
            },
            //获取下拉数据
            queryCurriculumList(){
                curriculumListApi().then(res => {
                    this.option1 = res.data.map(item => {
                        return {
                            text : item.typeName,
                            value : item.id
                        }
                    })
                    this.option1.unshift({text: "全部", value: ""})
                })
            },
            // 获取列表数据
            queryList(){
                this.loading = true;
                pdListApi(this.queryParams).then(res => {
                    console.log(res.rows)
                    this.tableData = res.rows;
                    this.total = res.total;
                    this.loading = false;
                })
            },
            //改变类型
            chooseItem(){
                this.queryList()
            }
        },
        activated() {
            // if(this.scroll > 0){
                document.documentElement && document.getElementsByClassName("content")[0].scrollTo(0, this.scroll);
                this.scroll = 0;
                window.addEventListener('scroll', this.handleScroll,true);
            // }
        },
        deactivated(){
            window.removeEventListener('scroll', this.handleScroll,true);
        },
        mounted() {
            this.queryCurriculumList();
            this.queryList();
            window.addEventListener('scroll', this.handleScroll,true);
        }
    }
</script>



<style lang="scss" scoped>
    .pd_list{
        width: 100%;
        height: 100%;
        font-size: 30px;

        .header{
            position: fixed;
            left: 0;
            top: 0;
            height: 90px;
            width: 100%;
            background-color: #4DB3B3;
            padding: 23px 43px 10px 43px;
            box-sizing: border-box;
            color: #FFFFFF;
            z-index: 100;

            .plan_id{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 36px;
                font-weight: bold;

                .icon_top{
                    font-size: 40px;
                    font-weight: bold;
                }
                .icon_top_right{
                    width: 50px;
                    height: 40px;
                }
            }
        }
        
        .body{
            overflow: hidden;
            width: 100%;
            height: 100%;
            // background:#E3E3E3;
            // border: 1px solid red;

            .content{
                width: 100%;
                position: absolute;
                top: 90px;
                bottom: 0;
                overflow-y: scroll;
                box-sizing: border-box;
                padding: 30px 32px 30px 32px;
                box-sizing: border-box;
                background:#E3E3E3;

                .title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left_title{
                        color: #606060;
                        .all_class{
                            
                        }

                        .num{
                            margin-left: 20px;
                            color: #4EB8B8;
                            font-weight: bold;
                        }
                    }

                    

                    .right_select{

                        /deep/.van-cell{
                            font-size: 36px;
                            padding-left: 50px;
                        }

                        /deep/.van-dropdown-menu__bar{
                            height: auto;
                            background: none;
                            box-shadow:none;

                            .van-dropdown-menu__title{
                                padding: 0 30px;
                                

                                .van-ellipsis{
                                    color: #4EB8B8;
                                }
                            }
                            .van-dropdown-menu__title::after{
                                border-color:transparent transparent #4EB8B8 #4EB8B8;
                            }
                        }
                    }
                }

                .text_content{
                    
                    .item{
                        margin-top: 20px;
                        .top_img{
                            width: 100%;
                            height: 289px;
                            text-align: center;

                            /deep/.class-img{
                                width: 100%;
                                height: 289px;
                                position: relative;

                                .van-image__img{
                                    width: 100%;
                                    height: 289px;
                                    border-radius: 15px 15px 0 0;
                                    object-fit: cover;
                                }
                                .classification {
                                    position: absolute;
                                    right: 10px;
                                    top: 10px;
                                    width: 120px;
                                    height: 50px;
                                    background: #55b8bc;
                                    text-align: center;
                                    font-size: 24px;
                                    line-height: 50px;
                                    color: #ffffff;
                                    border-radius: 16px;
                                }
                                .yellow {
                                background: #a5c683;
                                }
                                .red {
                                background: #b795bc;
                                }
                                .blue {
                                background: #bc9f79;
                                }
                                .color1 {
                                background: #2d8780;
                                }
                                .color2 {
                                background: #e2733b;
                                }
                                .color3 {
                                background: #a4c5dd;
                                }
                                .color4 {
                                background: #ae74b7;
                                }
                                .color5 {
                                background: #4c6c7f;
                                }
                            }
                        }
                        .word_text{
                            // height: 330px;
                            background: white;
                            padding: 22px 25px;
                            border-radius: 0 0 15px 15px;
                            position: relative;

                            .title{
                                // height: 90px;
                                color: #1A355D;
                                font-size: 36px;
                                font-weight: bold;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                                // text-align: center;
                            }
                            
                            .introduction{
                                margin-top: 25px;
                                color: #7F7F7F;
                                font-size: 30px;
                                font-weight: 400;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                                // height: 75px;
                                line-height: 1.4;
                                word-break: break-all;
                                // border: 1px solid red;
                            }

                            .time{
                                margin-top: 35px;
                                // position: absolute;
                                // left: 25px;
                                // bottom: 20px;
                                display: flex;
                                align-items: center;

                                .clock_icon{
                                    width: 40px;
                                    height: 40px;
                                }
                                .proposal{
                                    color: #1A355D;
                                    font-size: 26px;
                                    margin-left: 15px;
                                }
                                .time_num{
                                    color: #A0A0A0;
                                    font-size: 26px;
                                }
                            }
                        }
                    }
                }
            }
            .content1{
                background:white;
            }
            .van-loading{
                top: 50%;
                left: 50%;
            }
        }
    }
</style>