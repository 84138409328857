/*
 * @Author: steven 1105624290@qq.com
 * @Date: 2022-07-19 15:56:19
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2022-07-19 15:59:20
 * @FilePath: /ytjj-mobile/src/api/pd/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js';

export function curriculumListApi() {
    return request({
        url: "pd/type/list",
        method: 'get'
    })
}
// 课程展示接口
export function pdListApi(data) {
    return request({
        url: "pd/course/type",
        method: 'get',
        params: data
    })
}

// 章节下拉接口
export function chapterListApi(data) {
    return request({
        url: "course/chapter/getAllChapter",
        method: 'get',
        params: data
    })
}

// 获取上传的pdf
export function coursePDF(data) {
    return request({
        url: "course/resource/list",
        method: 'get',
        params: data
    })
}